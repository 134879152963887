import { render, staticRenderFns } from "./MonitoringConfig.vue?vue&type=template&id=32e1d201&scoped=true"
import script from "./MonitoringConfig.vue?vue&type=script&lang=ts"
export * from "./MonitoringConfig.vue?vue&type=script&lang=ts"
import style0 from "./MonitoringConfig.vue?vue&type=style&index=0&id=32e1d201&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e1d201",
  null
  
)

export default component.exports