<template>
  <div>
    <div>
      <el-button type="primary" @click="onAdd">添加电路</el-button>
    </div>
    <el-form ref="form" :model="formData" :show-message="false" :rules="rules">
      <el-table :data="formData.tableData">
        <el-table-column prop="" label="排号">
          <template slot-scope="scope">
            <el-form-item :prop="`tableData.${scope.$index}.moduleNo`" :rules="[{ required: true, message: '请输入排号' }]">
              <el-input v-model="scope.row.moduleNo" placeholder="请输入排号"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="" label="控制电路名称">
          <template slot-scope="scope">
            <el-form-item :prop="`tableData.${scope.$index}.moduleName`" :rules="[{ required: true, message: '请输入控制电路名称' }]">
              <el-input v-model="scope.row.moduleName" placeholder="请输入控制电路名称"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="" label="数据点名称">
          <template slot-scope="scope">
            <el-form-item :prop="`tableData.${scope.$index}.moduleField`" :rules="[{ required: true, message: '请选择数据点名称' }]">
              <el-select v-model="scope.row.moduleField" placeholder="请选择数据点名称">
                <el-option :value="item.dicCode" :label="item.dicValue" v-for="(item, index) of controlCabinetModuleList"
                  :key="index"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="" label="控制类型">
          <template slot-scope="scope">
            <el-form-item :prop="`tableData.${scope.$index}.controlType`" :rules="[{ required: true, message: '请选择控制类型' }]">
              <el-radio-group v-model="scope.row.controlType">
                <el-radio label="1">单项控制</el-radio>
                <el-radio label="2">正反控制</el-radio>
              </el-radio-group>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="deleteGreenhouse(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button class="mt15" type="primary" @click="submit">保存</el-button>
  </div>
</template>

<script>
export default {
  name: 'GreenhouseConfig',
  props: {
    deviceId: String
  },
  data() {
    return {
      formData: {
        tableData: []
      },
      rules: {
        tableData: [
          { required: true, type: 'array', message: '请添加电路', trigger: 'change' }
        ]
      },
      controlCabinetModuleList: []
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    // do something
    this.loadDict()
    this.loadData()
  },
  methods: {
    onAdd() {
      this.formData.tableData.push({
        deviceId: this.deviceId, // 设备ID
        moduleNo: '', // 设备子模块编号
        moduleName: '', // 设备子模块名称
        moduleField: '', // 设备子模块标识, 参考字典 controlCabinetModule
        controlType: '' // 设备子模块控制类型 1,单向控制; 2,正反控制
      })
    },
    loadDict() {
      this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'controlCabinetModule' }).then(res => {
        this.controlCabinetModuleList = res.controlCabinetModule
      })
    },
    deleteGreenhouse(index) {
      this.formData.tableData.splice(index, 1)
    },
    loadData() {
      this.$axios.get(this.$apis.greenhouse.selectControlCabinetConfigById, { deviceId: this.deviceId }).then(res => {
        this.formData.tableData = res || []
      })
    },
    submit() {
      this.$refs.form.validate().then(() => {
        this.$axios.post(this.$apis.greenhouse.batchInsertControlCabinetConfig, this.formData.tableData).then(() => {
          this.$message.success('保存成功')
          this.loadData()
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 0;
}
</style>
